import React, { useEffect } from 'react'
import { useState } from 'react'
import Common from '../../Common';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import Loader from '../../../Loader';

export default function UpadteStatus({ value,reason }) {

    const { nodeurl, tokenValue, adminId } = Common();
    const { id } = useParams();

    const [openMod, setopenMod] = useState(false);
    const [loading, setloading] = useState(false)

    const [statusInp, setstatusInp] = useState({
        status: '',
        reason: '',
    })
    const [allReqStatus, setallReqStatus] = useState([]);
    useEffect(() => {
        setloading(true);
        const reqStatusFetch = async () => {
            await fetch(nodeurl + `admins/request_status/list`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            }).then(res => res.json())
                .then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        setallReqStatus(res.result);
                        setloading(false);
                    }
                    else {
                        alert(res.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        reqStatusFetch();
    }, [])


    const onStatusUPdate = async (e) => {
        e.preventDefault();
        setloading(true);
        const data = {
            adminid: adminId,
            status: statusInp.status,
            reason: statusInp.reason,
        }
        console.log(data)
        await fetch(nodeurl + `admins/servicestatus/${id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        }).then(res => res.json())
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: res.message,
                        showConfirmButton: false,
                        timer: 1000
                    }).then(function () {
                        window.location.reload();
                    });
                    setloading(false);

                }
                else {
                    alert(res.message);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    return (
        <>

            <div className='text-end'>
                Current Status : <span onClick={(e) => setopenMod(true)} className={` text-capitalize servicedetail ${(value === 'initial') ? 'initial' : (value === 'completed' || value === 'complete') ? 'completed' : 'pending'} `}>{value && value}</span>
                {
                    reason != '' ? <div className='mt-1'>Status Remarks : <span className='text-decoration-underline'>{reason && reason}</span></div> : null
                }
                
            </div>
            <div className="modal fade show" style={openMod ? { display: 'block' } : null} >
                <div className="modal-dialog ">
                    <form onSubmit={onStatusUPdate} className='position-relative'>
                        {
                            loading ? <Loader /> : null
                        }
                        <div className="modal-content bg-dark">
                            <div className="modal-header border-b border-secondary">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Status Upadte</h1>
                                <button onClick={(e) => setopenMod(false)} type="button" className="btn-close btn-close-white"></button>
                            </div>
                            <div className="modal-body ">
                                <div className='mb-3'>
                                    <label>Status*</label>
                                    <select className="form-select" name="status" required
                                        onChange={(e) => setstatusInp({ ...statusInp, status: e.target.value })} >
                                            <option  value="" >Select Status</option>
                                            {
                                                allReqStatus && allReqStatus.map((item,index)=>(
                                                    <option key={index} value={item.title} >{item.title}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                                <div className='mb-2'>
                                    <label>Reason*</label>
                                    <textarea required onChange={(e) => setstatusInp({ ...statusInp, reason: e.target.value })} className="form-control p-3" placeholder="Reason For status" ></textarea>
                                </div>
                            </div>
                            <div className="modal-footer border-t border-0 pt-0">

                                <button type="submit" className="btn btn-primary btn-sm">Save changes</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {
                openMod ? <div className='modal-backdrop fade show w-100 h-100'></div> : null
            }

        </>
    )
}
